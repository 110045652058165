import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image";

const AboutImg = ({ filename, alt }) => {
  const data = useStaticQuery(graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(width: 350)
              }
            }
          }
        }
      }
      `)

      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(filename);
      }); 

      if (!image) return null;

      const imageFixed = image.node.childImageSharp.gatsbyImageData;
      return <GatsbyImage className="rounded" alt={alt} loading='lazy' image={imageFixed} imgStyle={{ borderRadius: '100%' }}/>;
  };

AboutImg.propTypes = {
  filename: PropTypes.string,
  alt: PropTypes.string,
};

export default AboutImg;
