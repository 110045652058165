import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Fade } from "react-awesome-reveal";
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;
  const [direction, setDirection] = useState('');

  useEffect(() => {
    if (window.innerWidth > 769) {
      setDirection('left');
    } else {
      setDirection('down');
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade triggerOnce direction={direction} duration={1000} delay={500}>
          <h1 className="hero-title">
            {title} <span className="text-color-main">{name}</span>
            <br />
            {subtitle}
          </h1>
        </Fade>
        <Fade triggerOnce direction={direction} duration={1000} delay={1000}>
          <p className="hero-cta">
            <a className="cta-btn cta-btn--hero" href="#about">
              {cta || 'Know more'}
            </a>
          </p>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
