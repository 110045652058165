import React, { useContext, useEffect, useState } from 'react';
import { Fade } from "react-awesome-reveal";
import Tilt from 'react-parallax-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);
  const [directionText, setDirectionText] = useState('');
  const [directionImg, setDirectionImg] = useState('');

  useEffect(() => {
    if (window.innerWidth > 769) {
      setDirectionText('left');
      setDirectionImg('right');
    } else {
      setDirectionText('up');
      setDirectionImg('up');
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Projects" />
          {projects.map(project => {
            const { id, title, info, info2, url, repo, img } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    triggerOnce
                    direction={directionText}
                    duration={1000}
                    delay={500}
                  >
                    <div className="project-wrapper__text">
                      <h3 className="project-wrapper__text-title">{title || 'Project Title'}</h3>
                      <div>
                        <p>
                          {info ||
                            'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
                        </p>
                        <p className="mb-4">{info2 || ''}</p>
                      </div>
                      {url && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn cta-btn--hero"
                          href={url || '#!'}
                          title='Go to Github'
                        >
                          Source Code
                        </a>
                      )}
                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn cta-btn--hero"
                          href={repo}
                          title='Go to Google Play Store'
                        >
                          See Live
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    triggerOnce
                    direction={directionImg}
                    duration={1000}
                    delay={1000}
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url || repo}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                        title={title}
                      >
                        <Tilt
                        tiltMaxAngleX={8}
                        tiltMaxAngleY={8}
                        perspective={1000}
                        scale={1}
                        speed={300}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                        </Tilt>
                      </a>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
