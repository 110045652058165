import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import AboutMe from './AboutMe/AboutMe';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Layout from './Layout/Layout';

import { PortfolioProvider } from '../context/context';

import { headerData, heroData, aboutData, projectsData, contactData, footerData } from '../mock/data';


function App() {
  const [header, setHeader] = useState({});
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHeader({ ...headerData });
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ header, hero, about, projects, contact, footer }}>
      <Layout>
      <Hero />
      <AboutMe />
      <Projects />
      <Contact />
      </Layout>
    </PortfolioProvider>
  );
}

export default App;
